@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
body > .wrap {
  padding-left: 8vw;
}

@media only screen and (max-width: 834px) {
  body > .wrap {
    padding-left: 0;
  }
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.8em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

@media only screen and (max-width: 1200px) {
  body {
    line-height: 2em;
  }
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  position: relative;
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 1600px) {
  #contents {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #contents {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 640px) {
  #contents {
    padding: 0 30px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #ee7800;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  position: relative;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 80px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto !important;
  background: white;
}

.hedcont {
  border-top: 3px solid #ff9a34;
  background: #fff8f0;
  border-bottom: 1px solid #ccc;
}

.hedcont .inner {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 834px) {
  .hedcont .inner .tel2 a {
    height: 60px;
    display: block;
    border-radius: 30px;
    padding: 10px 30px;
    background: #88e227;
  }
}

@media only screen and (max-width: 640px) {
  .hedcont .inner .tel2 a {
    padding: 10px 20px;
  }
}

.hedcont .inner .tel2 a p {
  display: block;
  text-align: left;
  line-height: 1;
  font-size: 20px;
  color: #222;
  font-weight: 700;
}

@media only screen and (max-width: 834px) {
  .hedcont .inner .tel2 a p {
    color: #fff;
  }
}

.hedcont .inner .tel2 a p i {
  margin-right: 5px;
}

.hedcont .inner .tel2 a p:nth-child(2) {
  margin-top: 5px;
  font-weight: 400;
  font-size: 13px;
}

.hedcont .inner .cont {
  margin-left: 20px;
}

.hedcont .inner .cont a {
  font-weight: 700;
  color: #fff;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 16px;
  display: block;
  background: #ff9a34;
}

@media only screen and (max-width: 834px) {
  .hedcont .inner .cont a {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hedcont .inner .cont a i {
  margin-right: 5px;
}

#header {
  width: 100%;
  height: 100%;
}

#header .inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

@media only screen and (max-width: 1200px) {
  #header .inner {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner {
    padding: 0;
  }
}

#header .inner .flxL {
  width: auto;
}

@media only screen and (max-width: 1200px) {
  #header .inner .flxL {
    width: 28%;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .flxL {
    width: 40%;
    position: fixed;
    top: 0;
    z-index: 12;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner .flxL {
    right: 40px;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .flxL {
    right: 30px;
  }
}

#header .inner .flxL #siteID a {
  display: block;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxL #siteID a {
    width: 80%;
    margin-left: auto;
  }
}

#header .inner .flxL #siteID a img {
  max-width: 175px;
  width: 100%;
}

#header .inner .flxR {
  width: 68%;
}

#hednav {
  padding: 20px 0;
}

#hednav .wrap .navwrap {
  width: 100%;
}

#hednav .wrap .navwrap #nav_global {
  width: 100%;
}

#hednav .wrap .navwrap #nav_global > ul {
  height: 100%;
  display: flex;
  justify-content: center;
}

#hednav .wrap .navwrap #nav_global > ul > li {
  flex-grow: 1;
}

#hednav .wrap .navwrap #nav_global > ul > li a {
  display: block;
  height: 100%;
  width: 100%;
  font-weight: 700;
  line-height: 1em;
  color: #7b4b00 !important;
  padding: 5px 0;
  font-size: 14px;
}

@media only screen and (max-width: 1200px) {
  #hednav .wrap .navwrap #nav_global > ul > li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1000px) {
  #hednav .wrap .navwrap #nav_global > ul > li a {
    font-size: 14px;
  }
}

#hednav .wrap .navwrap #nav_global > ul > li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#hednav .wrap .navwrap #nav_global > ul > li a i {
  font-size: 20px;
  color: #ee7800;
  display: block;
  margin-bottom: 5px;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    background: #f4f7f6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80px;
    width: 80px;
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #000;
    line-height: 1;
    transition: 1s;
  }
  .global-menu-btn:hover {
    color: #fff;
    background: #ee7800;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    width: 80px;
    height: 80px;
    color: #fff;
    background-color: #ee7800;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    width: 80px;
    height: 80px;
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #fff;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .navdamy {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  #hednav {
    justify-content: center;
    height: 80px;
    width: 100%;
    margin: 0;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    background: #fff;
  }
  #hednav .wrap {
    padding: 0 !important;
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background-color: #f4f7f6;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap .btnwrap {
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  #hednav .wrap .navwrap .btnwrap .btn01 {
    width: 48%;
  }
  #hednav .wrap .navwrap .btnwrap .btn01 a {
    display: block;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    background-color: #afd4f3;
    border: 1px solid #fff;
    padding: 10px 15px;
  }
  #hednav .wrap .navwrap #hed_info {
    width: 100%;
    padding: 0 20px;
  }
  #hednav .wrap .navwrap #hed_info .tel2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0;
  }
  #hednav .wrap .navwrap #hed_info .tel2 a {
    width: 100%;
    text-align: center;
    font-weight: 500;
    border-radius: 50px;
    position: relative;
    padding: 15px 20px;
    display: block;
    font-size: 16px;
    color: #fff;
    opacity: 1;
    background: #88e227;
    border: 2px solid #88e227;
    overflow: hidden;
    position: relative;
  }
  #hednav .wrap .navwrap #hed_info .tel2 a:hover:before {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  #hednav .wrap .navwrap #hed_info .tel2 a:before {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
  }
  #hednav .wrap .navwrap #hed_info .tel2 a i {
    margin-right: 5px;
  }
  #hednav .wrap .navwrap #hed_info .cont {
    padding-bottom: 20px;
  }
  #hednav .wrap .navwrap #hed_info .cont a {
    width: 100%;
    text-align: center;
    font-weight: 500;
    border-radius: 50px;
    position: relative;
    padding: 20px 30px;
    display: block;
    font-size: 18px;
    color: #fff;
    opacity: 1;
    background: #88e227;
    border: 2px solid #88e227;
    overflow: hidden;
    position: relative;
  }
  #hednav .wrap .navwrap #hed_info .cont a:hover:before {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  #hednav .wrap .navwrap #hed_info .cont a:before {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
  }
  #hednav .wrap .navwrap #hed_info .cont a i {
    margin-right: 5px;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul {
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global > ul > li {
    text-align: left;
    position: relative;
    width: 100%;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 36px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a i {
    margin-bottom: 0;
    margin-right: 5px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul {
    width: 100%;
    position: static;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul li {
    width: 48%;
    margin-bottom: 20px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul li a {
    font-weight: 500;
    padding: 25px 36px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p {
    position: relative;
    display: block;
    line-height: 1;
    padding: 25px 36px;
    font-size: 16px;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:hover:after {
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span {
    display: block;
    margin-top: 7px;
    font-size: 0.7em;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:after {
    border: solid 1px #ddd;
    font-weight: 100;
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    display: block;
    position: absolute;
    right: 0;
    top: -1px;
    bottom: 0;
    width: 66px;
    height: 66px;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7f6;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p.open:after {
    content: "\f00d";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes mainscl {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mainscl2 {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

#mainArea {
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
}

@media only screen and (max-width: 834px) {
  #mainArea {
    width: 130% !important;
    margin-right: -15%;
  }
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

.moveRel {
  overflow: hidden;
  position: relative;
}

.moveWrap {
  z-index: 999;
  background: #88e227;
  position: absolute;
  width: 0;
  top: 0;
}

#local-keyvisual {
  position: relative;
  background-position: center;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  display: inline-block;
  z-index: 1;
  position: relative;
  color: #222;
  text-align: center;
  font-weight: 700;
  padding: 150px 0;
  line-height: 1.4em;
  font-size: 35px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual h1 {
    padding: 100px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 30px;
    padding: 80px 40px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 30px;
    padding: 80px 30px;
  }
}

#local-keyvisual h1 span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  font-size: 0.5em;
}

#local-keyvisual h1:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #mainArea2 {
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 50px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #333;
}

footer#global_footer {
  margin-top: 80px;
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #333;
}

@media only screen and (max-width: 640px) {
  footer#global_footer {
    margin-top: 80px;
  }
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer2 {
  background: url(../images/common/bg.jpg);
  padding: 80px 0;
}

#footer2 .inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer2 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner {
    display: block;
  }
}

#footer2 .inner .flxL {
  width: 35%;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .flxL {
    width: 100%;
  }
}

#footer2 .inner .flxL .cont_5wrap {
  height: 100%;
  background: #ee7800;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .img {
  margin: 0 !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .img img {
  width: 100%;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .txt {
  text-align: left !important;
  padding: 20px !important;
  margin: 0 !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .txt div {
  text-align: left !important;
  color: #fff !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .tel2 {
  margin: 0 !important;
  padding: 0 20px 20px !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .tel2 div {
  text-align: left !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .tel2 div a {
  font-weight: 700 !important;
  font-size: 25px !important;
  color: #fff !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .tel2 div a i {
  margin-right: 5px !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .timetable {
  margin: 0 !important;
  padding: 0 20px 20px !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .timetable td {
  font-size: 14px !important;
  border: 0 !important;
  color: #fff !important;
  padding: 10px !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .timetable .ttl {
  border-right: 1px solid #fff !important;
  text-align: left !important;
  width: 150px !important;
  font-weight: 700 !important;
}

#footer2 .inner .flxL .cont_5wrap .cont_5 .timetable .bort {
  border-top: 1px solid #fff !important;
}

#footer2 .inner .flxR {
  width: 65%;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .flxR {
    width: 100%;
  }
}

#footer2 .inner .flxR iframe {
  height: 100% !important;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .flxR iframe {
    height: 400px !important;
  }
}

#footer {
  color: #333;
  background: url(../images/common/bg_1.jpg) center no-repeat;
  background-size: cover;
  padding: 80px 10px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer {
    padding: 80px 40px;
  }
}

@media only screen and (max-width: 640px) {
  #footer {
    padding: 80px 30px;
  }
}

#footer .inner {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#footer .inner .ttl {
  margin-bottom: 30px;
  width: 100%;
  font-weight: 700;
  color: #222;
  font-size: 25px;
}

@media only screen and (max-width: 834px) {
  #footer .inner .ttl {
    font-size: 20px;
  }
}

#footer .inner p {
  width: 100%;
  display: block;
  text-align: center;
  margin: 0 0 30px;
}

#footer .inner .fnav {
  max-width: 760px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  #footer .inner .fnav {
    display: block;
  }
}

#footer .inner .box {
  width: 30%;
}

@media only screen and (max-width: 834px) {
  #footer .inner .box {
    width: 100%;
    margin-bottom: 15px;
  }
}

#footer .inner .box a {
  text-align: center;
  font-weight: 500;
  border-radius: 50px;
  position: relative;
  padding: 15px 30px;
  display: block;
  font-size: 16px;
  color: #fff;
  opacity: 1;
  background: #88e227;
  border: 2px solid #88e227;
  overflow: hidden;
  position: relative;
}

#footer .inner .box a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

#footer .inner .box a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

#copy {
  background: #ee7800;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff !important;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 10px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #ee7800;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #ee7800;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #ee7800;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn {
  max-width: 300px;
}

@media only screen and (max-width: 834px) {
  .btn {
    max-width: 100%;
  }
}

.btn a {
  text-align: center;
  font-weight: 500;
  border-radius: 50px;
  position: relative;
  padding: 15px 30px;
  display: block;
  font-size: 16px;
  color: #fff;
  opacity: 1;
  background: #88e227;
  border: 2px solid #88e227;
  overflow: hidden;
  position: relative;
}

.btn a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .btn2 {
    display: block !important;
  }
}

.btn2 .box {
  max-width: 300px;
  margin: 0 10px;
}

@media only screen and (max-width: 834px) {
  .btn2 .box {
    max-width: 100%;
    margin: 0 0 10px;
  }
}

.btn2 a {
  text-align: center;
  font-weight: 500;
  border-radius: 50px;
  position: relative;
  padding: 15px 30px;
  display: block;
  font-size: 16px;
  color: #fff;
  opacity: 1;
  background: #88e227;
  border: 2px solid #88e227;
  overflow: hidden;
  position: relative;
}

.btn2 a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn2 a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.btn2 a i {
  margin-right: 5px;
}

#col_main {
  position: relative;
}

.kazari01:before {
  content: "";
  background: url(../images/common/kazari_3.png) no-repeat left center;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  z-index: -1;
}

.kazari02:before {
  content: "";
  background: url(../images/common/kazari_4.png) no-repeat right top;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
  z-index: -1;
}

.cont_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: wrap;
  max-width: 1400px;
}

@media only screen and (max-width: 1400px) {
  .cont_1 {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 {
    display: block;
  }
}

.cont_1:nth-child(odd) {
  flex-direction: row-reverse;
}

.cont_1 .flxL {
  width: 59%;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .cont_1 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_1 .flxL img {
  width: 100% !important;
}

.cont_1 .flxR {
  width: 38%;
}

@media only screen and (max-width: 834px) {
  .cont_1 .flxR {
    width: 100%;
  }
}

.cont_1 .flxR .txt ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.cont_1 .flxR .txt ul li:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  content: "\f14a";
  margin-right: 10px;
}

.cont_2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  background: #fff;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_2 {
    padding: 80px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_2 {
    padding: 80px 40px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    padding: 80px 30px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 {
    display: block;
  }
}

.cont_2 .flxL {
  width: auto;
}

@media only screen and (max-width: 834px) {
  .cont_2 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_2 .flxR {
  width: 85%;
}

@media only screen and (max-width: 834px) {
  .cont_2 .flxR {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .newswrap {
    padding: 10px;
    background: #f7f7f7;
  }
}

.newswrap .news {
  background: #fff;
  padding: 10px;
  overflow-y: auto;
  height: 300px;
  z-index: 1;
  position: relative;
  padding-right: 40px;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-right: 0;
  }
}

.newswrap .news .blog_list {
  border: 0;
}

.newswrap .news .blog_list .blog_photo {
  display: none;
}

.newswrap .news .blog_list > div {
  border-bottom: 1px dashed #333;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.newswrap .news .blog_list .blog_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 800;
  color: #111;
}

.newswrap .news .blog_list .blog_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.newswrap .news .blog_list .blog_text .txtwrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.newswrap .news .blog_list .blog_text .txtwrap ul {
  display: block;
}

.newswrap .news .blog_list .blog_text .txtwrap ul li a {
  margin-left: 10px;
  display: block;
  font-size: 12px;
  border: 1px solid #333;
  color: #111;
  border-radius: 20px;
  padding: 3px 10px;
}

.newswrap .news .blog_list .blog_text h3 {
  margin-bottom: 0;
}

.newswrap .news .blog_list .blog_text h3 a {
  font-weight: 400;
  font-size: 16px;
  color: #111;
}

.newswrap .news .blog_list .blog_text .detail {
  display: none;
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.newswrap .news dl dt, .newswrap .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_3:after {
  content: "";
  background: url(../images/common/kazari_1.png) no-repeat right top;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@media only screen and (max-width: 1200px) {
  .cont_3:after {
    right: -10%;
    top: -5%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3:after {
    right: -30%;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_3 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner {
    padding: 0 30px;
  }
}

.cont_3 .inner .txt {
  max-width: 760px;
}

.cont_4 {
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_4 {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_4 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    padding: 0 30px;
  }
}

.cont_4 a {
  width: 100% !important;
  height: auto !important;
}

.cont_4 img {
  width: 100% !important;
  height: auto !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi article h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  color: #222;
  font-size: 25px;
}

@media only screen and (max-width: 834px) {
  .midashi article h2 {
    font-size: 20px;
  }
}

.midashi article h2:before {
  content: "";
  background: url(../images/common/icon_1.png) no-repeat center;
  width: 50px;
  height: 36px;
  display: block;
  margin-right: 10px;
}

.midashi2 h2 {
  line-height: 1.7em;
  font-weight: 700;
  color: #222;
  font-size: 25px;
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

.midashi2 h2 span {
  color: #ee7800;
}

.midashi3 h2 {
  font-weight: 700;
  background: #f7f7f7;
  padding: 20px 30px;
  position: relative;
  border: 1px solid #ccc;
  font-size: 25px;
}

@media only screen and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

.midashi3 h2:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  border-radius: 500px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #ee7800;
}

.midashi4 h3 {
  padding-bottom: 5px;
  border-bottom: 3px double #4e362a;
  display: flex;
  align-items: center;
  color: #222;
  font-weight: 700;
  font-size: 18px;
}

.visit01 {
  line-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .visit01 {
    display: block;
  }
}

.visit01 .flxL {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .visit01 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.visit01 .flxL img {
  width: 100% !important;
}

.visit01 .flxR {
  width: 55%;
}

@media only screen and (max-width: 640px) {
  .visit01 .flxR {
    width: 100%;
  }
}

.visit02 {
  border: 2px solid #ee7800;
}

.visit02 h2 {
  padding: 20px;
  color: #fff;
  background: #ee7800;
  font-size: 20px;
}

.visit02 h2 i {
  margin-right: 10px;
}

.visit02 div {
  padding: 30px;
}

.waku {
  padding: 30px;
  margin: 2em 0;
  background: white;
  border-top: solid 5px #88e227;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
}

.waku h3 {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #222;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.news01 {
  padding: 80px 0;
  background: url(../images/common/bg.jpg);
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.news01:nth-child(even) {
  background: none;
}

.news01 .inner {
  line-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .news01 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .news01 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 640px) {
  .news01 .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 834px) {
  .news01 .inner {
    display: block;
  }
}

.news01 .flxL {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .news01 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.news01 .flxL img {
  width: 100% !important;
}

.news01 .flxR {
  width: 55%;
}

@media only screen and (max-width: 834px) {
  .news01 .flxR {
    width: 100%;
  }
}

.news01 h2 {
  padding: 20px;
  color: #fff;
  background: #ee7800;
  font-size: 20px;
}

.news01 h2 i {
  margin-right: 10px;
}

.access01 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .access01 {
    margin-left: -2.1097%;
  }
}

@media only screen and (max-width: 640px) {
  .access01 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.access01 .box {
  position: relative;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .access01 .box {
    width: 31.2236%;
    margin-left: 2.1097%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .access01 .box {
    width: 48%;
    margin-left: 0;
  }
}

.access01 .box img {
  width: 100% !important;
}

.access01 .box article h3 {
  margin: 10px 0 5px;
  font-weight: 600;
  color: #222;
  text-align: center;
}

.access01 .box article div {
  text-align: center;
}

.contact01 a {
  background: #ee7800;
  padding: 20px 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  max-width: 370px;
  width: 100%;
  text-align: center;
}

.contact01 span {
  padding: 20px 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  display: inline-block;
  max-width: 370px;
  width: 100%;
  text-align: center;
}

.reason01 td {
  border: 0 !important;
}

.reason01 .ttl {
  background: rgba(136, 226, 39, 0.1);
}

.reason01 .store, .reason01 .borb {
  border-bottom: 1px solid #ccc !important;
}

.concept02 {
  line-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  .concept02 {
    display: block;
  }
}

.concept02 .flxL {
  width: 35%;
}

@media only screen and (max-width: 834px) {
  .concept02 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.concept02 .flxL img {
  width: 100% !important;
}

.concept02 .flxR {
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .concept02 .flxR {
    width: 100%;
  }
}

.support01 {
  line-height: 2em;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .support01 {
    display: block !important;
  }
}

.support01 .box {
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .support01 .box {
    width: 100%;
    margin-bottom: 50px;
  }
}

.support01 .box img {
  width: 100% !important;
  margin-bottom: 20px;
}

.support01 .box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.support02 {
  line-height: 2em;
  border: 1px solid #ccc;
  padding: 50px;
}

@media only screen and (max-width: 640px) {
  .support02 {
    padding: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .support03 img {
    width: 100% !important;
  }
}

.support03 h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

section form p.annot {
  display: none;
}

.error {
  max-width: 1200px;
  margin: 0 auto;
}

.oem01 {
  display: flex;
  justify-content: space-between;
}

.oem01 .box {
  width: 48%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 640px) {
  .oem01 .box {
    width: 100%;
  }
  .oem01 .box:nth-child(n + 2) {
    margin-bottom: 30px !important;
  }
  .oem01 .box:last-child {
    margin-bottom: 0 !important;
  }
}

.oem01 .box:nth-child(n + 2) {
  margin-bottom: 0;
}

.oem01 .box img {
  width: 100% !important;
  margin-bottom: 20px;
}

.oem01 .box h3 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.information01 {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .information01 {
    display: block;
  }
}

.information01 .flxL {
  width: 25%;
}

@media only screen and (max-width: 640px) {
  .information01 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.information01 .flxL img {
  width: 100% !important;
}

.information01 .flxR {
  width: 73%;
}

@media only screen and (max-width: 640px) {
  .information01 .flxR {
    width: 100%;
    margin-bottom: 30px;
  }
}

.information01 .flxR .txt .box {
  width: 100%;
}

.information01 .flxR .txt .box h3 {
  color: #fff;
  padding: 5px 10px;
  background: #ee7800;
  display: block;
}

.information01 .flxR .txt .box div {
  padding: 5px 10px;
}

.flow01 .flow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .flow01 .flow {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .flow01 .flow div {
    width: 100% !important;
  }
}

.flow01 .flow > div {
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .flow01 .flow > div {
    width: 100% !important;
  }
}

.flow01 .flow .img {
  width: 35%;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .flow01 .flow .img {
    width: 100% !important;
    margin-bottom: 30px;
  }
}

.flow01 .flow .img:before {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  background: #ee7800;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  z-index: 10;
}

.flow01 .flow .img img {
  float: none !important;
  margin-right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.flow01:nth-child(1) .img:before {
  content: "1";
}

.flow01:nth-child(2) .img:before {
  content: "2";
}

.flow01:nth-child(3) .img:before {
  content: "3";
}

.flow01:nth-child(4) .img:before {
  content: "4";
}

.flow01:nth-child(5) .img:before {
  content: "5";
}

.flow01:nth-child(6) .img:before {
  content: "6";
}

.flow01:nth-child(7) .img:before {
  content: "7";
}

.flow01:nth-child(8) .img:before {
  content: "8";
}

.flow01:nth-child(9) .img:before {
  content: "9";
}

.faq01 dl {
  margin-bottom: 30px;
  display: block !important;
}

.faq01 dl:last-child {
  margin-bottom: 0 !important;
}

.faq01 dl dt {
  cursor: pointer;
  width: 100% !important;
  font-size: 18px;
  font-weight: 700;
  color: #181818;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
  margin-bottom: 10px;
}

@media only screen and (max-width: 640px) {
  .faq01 dl dt {
    font-size: 16px;
  }
}

.faq01 dl dt span {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.faq01 dl dt span:before {
  margin-right: 10px;
  font-family: "Font Awesome 5 Pro";
  content: "\f059";
  font-size: 30px;
  font-weight: 700;
  color: #88e227;
  vertical-align: middle;
  text-align: right;
  display: block;
}

.faq01 dl dd {
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 640px) {
  .faq01 dl dd {
    font-size: 16px;
  }
}

.faq01 dl dd .txt {
  font-size: 16px;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.faq01 dl dd span {
  margin-right: 0 !important;
  line-height: 1.7em !important;
}

.works01 {
  padding: 20px;
  border: solid 1px #ccc;
}

.comp2 dt, .comp2 dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.comp2 dt {
  border-bottom: 1px solid #ccc !important;
}

.comp2 dd {
  border-bottom: 1px solid #eee !important;
}

@media screen and (max-width: 640px) {
  .comp2 dl {
    display: flex;
    flex-flow: column;
  }
  .comp2 dt {
    border-bottom: 0 !important;
    background: #f9f9f9 !important;
    font-weight: bold;
  }
  .comp2 dt, .comp2 dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
}

.timelist {
  margin-bottom: 100px;
}

@media screen and (max-width: 47.2em) {
  .timelist {
    max-width: 640px;
    margin: 0 auto 60px;
  }
}

@media only screen and (max-width: 640px) {
  .timelist {
    margin-bottom: 60px;
  }
}

.cbp_tmtimeline {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background: #326dbf;
  left: 2%;
  margin-left: -10px;
}

.cbp_tmtimeline > li {
  position: relative;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 100%;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  margin: 0 0 20px 8%;
  letter-spacing: 0.05em;
  color: #326dbf;
  line-height: 1;
  font-weight: 700;
  position: relative;
  display: block;
  font-size: 25px;
  font-size: 1.30208vw;
}

@media only screen and (min-width: 1600px) {
  .cbp_tmtimeline > li .cbp_tmtime span {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline > li .cbp_tmtime span {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cbp_tmtimeline > li .cbp_tmtime span {
    font-size: 1.5em !important;
  }
}

@media only screen and (max-width: 640px) {
  .cbp_tmtimeline > li .cbp_tmtime span {
    font-size: 20px;
  }
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #326dbf;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 40px 8%;
  background: #dceaf5;
  color: #181818;
  padding: 30px;
  font-size: 16px;
  line-height: 1.6em;
  position: relative;
  border-radius: 5px;
}

.cbp_tmtimeline > li:nth-child(2) .cbp_tmlabel, .cbp_tmtimeline > li:nth-child(5) .cbp_tmlabel, .cbp_tmtimeline > li:nth-child(6) .cbp_tmlabel {
  background: #efefef;
}

.cbp_tmtimeline > li:nth-child(2) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(5) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(6) .cbp_tmlabel:after {
  border-right-color: #efefef;
}

.cbp_tmtimeline > li:nth-child(2) .cbp_tmicon:before, .cbp_tmtimeline > li:nth-child(5) .cbp_tmicon:before, .cbp_tmtimeline > li:nth-child(6) .cbp_tmicon:before {
  content: "\f1ad";
}

.cbp_tmtimeline > li .cbp_tmlabel h2 {
  margin-bottom: 30px;
  font-weight: 700;
  margin-top: 0px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #181818;
  color: #181818;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline > li .cbp_tmlabel h2 {
    font-size: 26px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cbp_tmtimeline > li .cbp_tmlabel h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 640px) {
  .cbp_tmtimeline > li .cbp_tmlabel h2 {
    font-size: 20px;
  }
}

.cbp_tmtimeline > li .cbp_tmlabel h3 {
  margin-bottom: 20px;
  font-weight: 700;
  margin-top: 30px;
  color: #181818;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    font-size: 16px;
  }
}

.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #dceaf5;
  border-width: 10px;
  top: 10px;
}

.cbp_tmtimeline > li .cbp_tmicon {
  width: 40px;
  height: 40px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  color: #181818;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 8px #649cd2;
  text-align: center;
  margin: 0 0 0 0;
}

.cbp_tmtimeline > li .cbp_tmicon:before {
  font-weight: 700;
  font-family: "Font Awesome 5 Pro";
  content: "\f406";
}

/* Example Media Queries */
@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 2em;
  }
}

@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left !important;
  }
  .cbp_tmtimeline > li .cbp_tmtime span:first-child {
    margin-bottom: 10px;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 50px 0;
    padding: 30px;
    font-weight: 400;
    font-size: 95%;
  }
  .cbp_tmtimeline > li .cbp_tmlabel h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #dceaf5;
    top: -20px;
  }
  .cbp_tmtimeline > li:nth-child(2) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(5) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(6) .cbp_tmlabel:after {
    border-right-color: transparent !important;
    border-bottom-color: #efefef;
  }
  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -55px 5px 0 0px;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
